import './App.css';

import logo from './logo.svg';

function App() {
  return (
    <div className="App">
      <header className="dark:bg-black App-header select-none">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="pt-10 dark:text-gray-300">
        Infrastructure <code>REBUILD<i class="fal fa-cog fa-fw App-loading"></i></code> in progress.
        </h1>
      </header>
    </div>
  );
}

export default App;
